import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";
import Footer from "../layout/Footer";
import "./Login.css";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const { setUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const register = () => navigate("/register");
  //const login = () => history.push("/login");

  const submit = async (e) => {
    e.preventDefault();
    try {
      const loginUser = { email, password };
      const loginRes = await Axios.post(
        "https://node-api-to-mong.vercel.app/users/login",
        loginUser
      );
      setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user,
      });
      localStorage.setItem("auth-token", loginRes.data.token);
      navigate("/appstartpg");
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg);
    }
  };
  return (
    <>
      <div className="">
        {/* <h2>This is the Log in and start page for the KPSDev.org
         Todo Application. Please register and login to try it out.
         The Login uses Jason Web Token for security.
       </h2> */}
        {/* <h2>Log in</h2> */}

        {error && (
          <ErrorNotice message={error} clearError={() => setError(undefined)} />
        )}

        <section className="join-main-section">
          <h1 className="join-text">
            Join the <br />
            <span className="accent-text">excitement!</span>
          </h1>

          <form className="join-form" onSubmit={submit}>
            <div className="input-group">
              <label htmlFor="login-email">Email</label>
              <input
                id="login-email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="username"
              />
            </div>
            <div className="input-group">
              <label htmlFor="login-password">Password</label>
              <input
                id="login-password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
            </div>
            <div className="input-group">
              <input type="submit" value="Join Now" style={{ float: "left" }} />
            </div>
            <div className="input-group">
              <div
                style={{
                  overflow: "hidden",
                  padding: ".5em",
                  fontSize: "12px",
                  color: "white",
                }}
              >
                If you have not Registered &nbsp;&nbsp;&nbsp;
              </div>
              <button
                className="RegBtn"
                onClick={register}
                style={{ float: "left" }}
              >
                Register
              </button>
            </div>
          </form>
        </section>
      </div>

      <div className="join-page-circle-1"></div>
      <div className="join-page-circle-2"></div>
      <Footer />
      {/* <ToastContainer position={toast.POSITION.TOP_RIGHT}/> */}
    </>
  );
}
