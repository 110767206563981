export default function AuthHeader() {
    const JWToken = localStorage.getItem('auth-token');

    //JSON.parse(localStorage.getItem('auth-token'));
     console.log(JWToken)
    if (JWToken) {
      // for Node.js Express back-end
     // console.log(JWToken)
      return ( JWToken );
    } else {
      return {};
    }
  }