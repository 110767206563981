import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";
import Footer from "../layout/Footer";
import "./Login.css";

export default function Register() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordCheck, setPasswordCheck] = useState();
  const [displayName, setDisplayName] = useState();
  const [error, setError] = useState();

  const { setUserData } = useContext(UserContext);
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();

    try {
      const newUser = { email, password, passwordCheck, displayName };
      const registerResponse = await Axios.post(
        "https://node-api-to-mong.vercel.app/users/register",
        newUser
      );

      // Check if the response is valid
      if (!registerResponse || !registerResponse.data) {
        throw new Error("Invalid response from registration API");
      }

      const loginRes = await Axios.post(
        "https://node-api-to-mong.vercel.app/users/login",
        {
          email,
          password,
        }
      );

      // Check if the login response is valid
      if (!loginRes || !loginRes.data) {
        throw new Error("Invalid response from login API");
      }

      // Set user data
      setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user,
      });

      localStorage.setItem("auth-token", loginRes.data.token);
      navigate("/");
    } catch (err) {
      // Log the error and set the error message
      console.error("Error during registration or login:", err);
      err.response && err.response.data.msg
        ? setError(err.response.data.msg)
        : setError("An unexpected error occurred.");
    }
  };

  return (
    <>
      <div className="page">
        {/* <h2>Register</h2> */}
        {error && (
          <ErrorNotice message={error} clearError={() => setError(undefined)} />
        )}

        <section className="join-main-section">
          <h1 className="register-text">
            Register to
            <br />
            <span className="accent-text">explore!</span>
          </h1>
          <form className="join-form" onSubmit={submit}>
            <div className="input-group">
              <label htmlFor="register-email">Email</label>
              <input
                id="register-email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label htmlFor="register-password">Password</label>
              <input
                id="register-password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                placeholder="Verify password"
                onChange={(e) => setPasswordCheck(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label htmlFor="register-display-name">Display name</label>
              <input
                id="register-display-name"
                type="text"
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </div>
            <div className="input-group">
              <input type="submit" value="Register" />
            </div>
          </form>
        </section>
      </div>
      <div className="join-page-circle-1"></div>
      <div className="join-page-circle-2"></div>
      <Footer />
    </>
  );
}
