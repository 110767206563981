import React, { useState, useEffect, useContext } from "react";
import TodoForm from "./TodoForm";
import Todo from "./Todo";
import Pagination from "./Pagination";
import AuthHeader from "./AuthHeader";
import Setpriority from "./Setpriority";
import UserContext from "../../../context/UserContext";
import axios from "axios";

const Axios = axios.create({
  baseURL: "https://node-api-to-mong.vercel.app",
});

function TodoList() {
  const [todos, setTodos] = useState([]);
  const [parentCounter, setParentCounter] = useState(0);
  //const [parentFilter, setParentFilter] = useState(0);
  //const [todo, setTodo] = useState ([])   // or try ({}) need to pass {"text":"Todo String"}
  //const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [todosPerPage] = useState(5);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const firstpage = (pageNumber) => setCurrentPage(1);
  const nextpage = (pageNumber) => setCurrentPage(pageNumber + 1);
  const prevpage = (pageNumber) => setCurrentPage(pageNumber - 1);
  const lastpage = (pageNumber) =>
    setCurrentPage(Math.ceil(todos.length / todosPerPage));
  const user = useContext(UserContext);

  //for Api retrieval on mount since [] null
  useEffect(() => {
    //  console.log(user) //returning user.id = '5f78e0375736cd5c2894550f'
    const userArray = Object.values(user);
    //  console.log(userArray)
    let result = userArray.map(({ user }) => user);
    // console.log(result)
    let result2 = result[0];
    //console.log(result2.id)
    let id = result2.id;

    let JWT = AuthHeader();
    const fetchData = async () => {
      var config = {
        url: `/todos/${id}`,
        method: "get",
        headers: {
          "x-auth-token": `${JWT}`,
          "content-type": "application/json",
        },
      };

      console.log(config);

      await Axios(config).then((res) => {
        const data = res.data;
        // console.log(data)
        const values = Object.values(data);

        console.log("ParentCounter =" + parentCounter);
        console.log(values);
        //  console.log(entries)
        //  const rvalues = values.reverse();
        // eslint-disable-next-line
        function cNum(param) {
          return param === "" ? NaN : Number(param);
        }

        const filter = values.filter(
          (todo) => todo.priority > cNum(parentCounter)
        );
        // console.log(filter);

        const rvalues = filter.reverse();
        //const rvalues = values.reverse();

        setTodos(rvalues);
      });
    };
    fetchData();
  }, [parentCounter, user]);

  const getTodos = async () => {
    //console.log(user) //returning user.id = '5f78e0375736cd5c2894550f'
    const userArray = Object.values(user);
    //console.log(userArray)
    let result = userArray.map(({ user }) => user);
    //console.log(result)
    let result2 = result[0];
    console.log(result2.id);
    let id = result2.id;

    let JWT = AuthHeader();
    const fetchData = async () => {
      var config = {
        url: `/todos/${id}`,
        method: "get",
        headers: {
          "x-auth-token": `${JWT}`,
          "content-type": "application/json",
        },
      };

      await Axios(config).then((res) => {
        const data = res.data;
        console.log(data);
        //const object1 = data
        // const newTodosArray = Object.keys(data);
        const values = Object.values(data);
        // const entries = Object.entries(data)

        console.log(values);
        //  console.log(entries)
        const rvalues = values.reverse();
        //At this point I would like to filter todos
        //by priority value only show this todos > [setPriority]
        // Need a Input type number that I set

        // console.log(JSON.stringify(data))
        // console.log(newTodosArray)
        //const NewTodosArray = object1.id
        setTodos(rvalues);
      });
    };
    fetchData();
  };

  const addTodo = async (todo) => {
    if (!todo.text || /^\s*$/.test(todo.text)) {
      return;
    }

    console.log(user); //returning user.id = '5f78e0375736cd5c2894550f'
    const userArray = Object.values(user);
    console.log(userArray);
    let result = userArray.map(({ user }) => user);
    console.log(result);
    let result2 = result[0];
    console.log("rid : " + result2.id);
    let id = result2.id;

    //wrap in a try catch for later or use .then for promise to catch errors
    // *** use notifications for errors.
    const newObjectT = todo;
    // console.log(newObjectT) ;
    console.log(newObjectT.text);
    let JWT = AuthHeader();

    let txt = newObjectT.text;
    console.log(txt);

    var config = {
      url: "/todos/todo",
      method: "post",
      headers: { "x-auth-token": `${JWT}`, "content-type": "application/json" },
      data: { text: `${txt}`, priority: 1, creator_id: `${id}` },
    };

    await Axios(config);
    //  console.log(res)
    getTodos();
  };

  const updateTodo = async (todoId, newValue) => {
    if (!newValue.text || /^\s*$/.test(newValue.text)) {
      return;
    }
    //  console.log(todos)
    //  setTodos(prev => prev.map(item => (item._id === todoId ? newValue : item)));
    let JWT = AuthHeader();
    var config = {
      url: "/todos/todo/" + todoId,
      method: "put",
      headers: { "x-auth-token": `${JWT}`, "content-type": "application/json" },
      data: { text: `${newValue.text}`, priority: `${newValue.priority}` },
    };

    await Axios(config);
    //  console.log(res)
    getTodos();
  };

  const removeTodo = async (todoId) => {
    //const removedArr = [...todos].filter(todo => todo.id !== id);
    //setTodos(removedArr);
    let JWT = AuthHeader();
    var config = {
      url: "/todos/todo/" + todoId,
      method: "delete",
      headers: { "x-auth-token": `${JWT}`, "content-type": "application/json" }, //,
      //data:{text:`${newValue.text}`}
    };

    await Axios(config);
    //  console.log(res)
    getTodos();
  };

  const completeTodo = (id) => {
    let updatedTodos = todos.map((todo) => {
      if (todo.id === id) {
        todo.isComplete = !todo.isComplete;
      }
      return todo;
    });
    setTodos(updatedTodos);
  };

  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

  return (
    <>
      <div className="todolistContainer">
        {/* <h1>Tasks to complete!</h1> */}
        <TodoForm onSubmit={addTodo} />
        Parent Counter: {parentCounter}
        <Setpriority setParentCounter={setParentCounter} />
        <Pagination
          currentPage={currentPage}
          todosPerPage={todosPerPage}
          totaltodos={todos.length}
          paginate={paginate}
          firstpage={firstpage}
          nextpage={nextpage}
          prevpage={prevpage}
          lastpage={lastpage}
        />
        <div className="bscroller">
          <div className="text">
            <Todo
              todos={currentTodos}
              completeTodo={completeTodo}
              removeTodo={removeTodo}
              updateTodo={updateTodo}
            />
            <Pagination
              currentPage={currentPage}
              todosPerPage={todosPerPage}
              totaltodos={todos.length}
              paginate={paginate}
              firstpage={firstpage}
              nextpage={nextpage}
              prevpage={prevpage}
              lastpage={lastpage}
            />
            <center>
              {/* <button className="postBtn" onClick={getTodos}>API Get</button>
     <button className="postBtn" onClick={getTodos}>API Get</button> */}
            </center>
          </div>
        </div>
      </div>
    </>
  );
}

export default TodoList;
