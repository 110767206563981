import React, {useState,  useRef } from 'react'

export default function Setpriority({ setParentCounter }) {
    const inputRef3 = useRef(null);
    const [filter, setFilter] = useState(0);
    
    /* useEffect(() => {
        // want update filter value by 1
       // keep in mind I can't update/mutate current state directly 
       //alert(filter)
       document.title = `You clicked ${filter} times`;
       
      
        }, [filter]);   */


      const handleChange = e => {
        setFilter(e.target.value);
        setParentCounter(filter)
         };


    return (
        <div>
         <input
            type="number"
            placeholder='Set P'
            // value={input2}
            onChange={handleChange}
            //onChange = {() => {
            //    setParentCounter(filter);
            //  }}
            name='priority'
            ref={inputRef3}
            className='set-priority'
            defaultValue={filter} 
          />
      
        </div>
    )
}
