import React from "react";
import { Link } from "react-router-dom";
import AuthOptions from "../auth/AuthOptions";
import Logo from '../../images/logo180x180.png'; 

export default function Header() {
 // const Title = "KPSDev.org"
   return (
  <>
  
  <header id="header">
  <Link to="/">
  {/* <h1 className="title">{Title}</h1> */}
<header className="main-header">
{/* <a href="/" className="brand-logo"> */}
<img className="logo" src={Logo} alt=""/>
<p className="brand-logo-name">KPSDev.org</p>
{/* <div className="brand-logo-name">KPSDev.org</div> */}
{/* </a> */}
</header>
  
  
  
      </Link>
      <AuthOptions />
    </header>
    </>
  );
}
