import React, { useState } from 'react';
import TodoForm from './TodoForm';
import { RiCloseCircleLine } from 'react-icons/ri';
import { TiEdit } from 'react-icons/ti';

const Todo = ({ todos, completeTodo, removeTodo, updateTodo }) => {
  const [edit, setEdit] = useState({
    _id: null,
    value: '',
    value2: 0
  });

  const submitUpdate = (value,value2) => {
    updateTodo(edit._id, value, value2);
    setEdit({
      _id: null,
      value: '',
      value2:0
    });
  };


  if (edit._id) {
    return <TodoForm edit={edit} onSubmit={submitUpdate} />;
  }

  return todos.map((todo, index) => (
    <div
      className={todo.isComplete ? 'todo-row complete' : 'todo-row'}
      key={index} >
    <div key={index} className="input-priority">
    {todo.priority}
     </div>
      
      <div   
      style={todo.priority === 10 ? {color:'red',backGround:"yelow"} : todo.priority === 7 ? {color:' greenyellow'} : todo.priority === 8 ? {color:'yellowgreen',fontWeight:'900'}: todo.priority === 9 ? {color:'yellow',fontWeight:'900'}:{color:'white'}} key={todo._id} onClick={() => completeTodo(todo._id)}>
        {todo.text}   
      </div>
      
      <div className='icons'>
      <RiCloseCircleLine
          onClick={() => removeTodo(todo._id)}
          className='delete-icon'
        />
        <TiEdit
          onClick={() => setEdit({ _id: todo._id, value: todo.text, value2:todo.priority })}
          className='edit-icon'
        />
      </div>
    </div>
  ));
};

export default Todo;
