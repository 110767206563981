import "./App.css";
import "./style2.css";
import "./style.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Axios from "axios";
import Header from "./components/layout/Header";
import AppStartPg from "./components/pages/AppStartPg";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Discover from "./components/pages/Discover";
import Home from "./components/pages/Home";
import UserContext from "./context/UserContext";

export default function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
  });

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        let token = localStorage.getItem("auth-token");
        if (token === null) {
          localStorage.setItem("auth-token", "");
          token = "";
        }
        const tokenRes = await Axios.post(
          "https://node-api-to-mong.vercel.app/users/tokenIsValid",
          null,
          { headers: { "x-auth-token": token } }
        );
        if (tokenRes.data) {
          const userRes = await Axios.get(
            "https://node-api-to-mong.vercel.app/users/",
            {
              headers: { "x-auth-token": token },
            }
          );
          setUserData({
            token,
            user: userRes.data,
          });
        }
      } catch (error) {
        console.error("Error checking logged in status:", error);
        // Handle error appropriately (e.g., show a notification)
      }
    };

    checkLoggedIn();
  }, []);
  return (
    <>
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Header />
          <div className="container">
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route exact path="/appstartpg" element={<AppStartPg />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/discover" element={<Discover />} />
            </Routes>
          </div>
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
}

// export default App;
