import React from 'react'
import{Link} from "react-router-dom";
import './Nav.css'

export default function Nav() {
    return (
        <nav className='nav'>
         {/* <h3>...</h3> */}
         <div className='nav-links'>
             <Link id="todo1" to="/todolist" >
             <div>Add Todos</div>
             </Link>
             <Link to="/displaytasks">
             <div>Display Todos</div>
             </Link>
          </div>   
        </nav>
    )
}
