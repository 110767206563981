import React from 'react';
import { IconContext } from "react-icons";
import { AiOutlineFastBackward, AiOutlineStepBackward,AiOutlineStepForward, AiOutlineFastForward} from "react-icons/ai";


const Pagination = ({ todosPerPage, totaltodos, firstpage, nextpage, prevpage, lastpage, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totaltodos / todosPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    
      <div className='pagenav'>
      <button onClick={() => firstpage(currentPage)} disabled={currentPage === 1 ? true : false} className='btn1'>
      <IconContext.Provider
      value={{ color: 'white', size: '30px'}}>
      <AiOutlineFastBackward /> 
      </IconContext.Provider>
            </button>
         <button onClick={() => prevpage(currentPage)} disabled={currentPage === 1 ? true : false} className='btn1'>
         <IconContext.Provider
      value={{ color: 'white', size: '30px'}}
    >         <AiOutlineStepBackward/> 
      </IconContext.Provider>  
            </button>
            <input type="text" className="pgNo" onChange={()=>{}} value={`Pg ${currentPage}`} />
          <button onClick={() => nextpage(currentPage)}  className='btn1' disabled={currentPage === Math.ceil(totaltodos / todosPerPage) ? true : false}>
          <IconContext.Provider
      value={{ color: 'white', size: '30px'}}>
          <AiOutlineStepForward/>
          </IconContext.Provider>   
            </button>
            
         <button onClick={() => lastpage(currentPage)} disabled={currentPage === Math.ceil(totaltodos / todosPerPage) ? true : false} className='btn1'>
         <IconContext.Provider
      value={{ color: 'white', size: '30px'}}>
          <AiOutlineFastForward/>
          </IconContext.Provider>   
                </button>
        
          </div>
      );
};

export default Pagination;
