import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ErrorNotice = ({ message, clearError }) => {
  useEffect(() => {
    if (message) {
      toast.error(message); // Display the error message
      clearError(); // Optionally clear the error after displaying
    }
  }, [message, clearError]);

  return (
    <>
      <ToastContainer position="top-right" />
    </>
  );
};

export default ErrorNotice;
