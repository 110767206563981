import React, { Component } from 'react'
import './Footer.css'

import styled from 'styled-components'


const Atag = styled.a`
color:white;`



export default class Footer extends Component {
    render() {
        return (
            <div>
              <footer >
              <div className="footerLinks">
		          <div className="left-nav">
              <ul>
			        <li><Atag href="/">Privacy Policy</Atag></li>
  			      <li><a href="/">Legal</a></li>
  			      <li><a href="/">Site Map</a></li>
  			      <li><a href="/">Contact Us</a></li>
		          </ul>
	            </div>
              <div className="right-nav">
              <ul>
			        <li><Atag href="/">LinkedIn</Atag></li>
  			      <li><a href="/">GitHub</a></li>
  			      <li><a href="/">KPSDev.org</a></li>
  			      <li><a href="/">FreeCodeCamp</a></li>
		          </ul>
	            </div>
              </div>
	           <div className="copyright">
		          <p>Copyright 2021</p>
             </div>
                </footer>   
            </div>
        )
    }
}
