import React, { Component } from "react";
import "./TodoApp.css";
import TodoList from "./todocomponents/TodoList";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DisplayTasks from "./todocomponents/DisplayTasks";
import Nav from "./todocomponents/Nav";

class TodoApp extends Component {
  /* constructor() {
    super();
    this.state = {
      name: "React",
      isUserAuthenticated: true
    };
  }
 */

  componentDidMount() {
    console.log("Mounted");

    document.getElementById("todo1").click();
  }

  render() {
    return (
      <>
        <Router>
          <div className="todo-app">
            <Nav />
            <Routes>
              <Route path="/" exact element={<TodoList />} />
              <Route path="/displaytasks" element={<DisplayTasks />} />
              <Route path="/todolist" element={<TodoList />} />
            </Routes>
          </div>
        </Router>
      </>
    );
  }
}

export default TodoApp;
