import React, { Component } from 'react'
import Footer from '../layout/Footer'
import './Discover.css'
import More from '../../images/more.svg'; 
import Micr from '../../images/microphone.svg'; 
import Albums from '../../images/albums.svg'; 
import Covers from '../../images/covers.webp'; 

export default class Discover extends Component {
    render() {
        return (
            <>
              <div className="container full-height-grow">
                <section className="discover-main-section">
                <div className="call-to-action">
                  <h1 className="title">Discover new code solutions!</h1>
                  <div className="icon-section">
                    <div className="icon">
                      <img src={Micr} alt=""/>
                      React
                    </div>
                    <div className="icon">
                      <img src={Albums} alt=""/>
                      Apps
                    </div>
                    <div className="icon">
                      <img src={More} alt=""/>
                      More
                    </div>
                  </div>
                  By joining you can test out our latest app with API to MongoDb in cloud along with JWT login.
                </div>
                <img className="covers-image" src={Covers} alt=""/>
              </section>
              </div>
            <Footer />
        </>
        
        )
    }
}
