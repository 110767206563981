import React, { useState, useEffect, useContext } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import "./DisplayTasks.css";
import AuthHeader from "./AuthHeader";
import Setpriority from "./Setpriority";
import axios from "axios";
import UserContext from "../../../context/UserContext";

const Axios = axios.create({
  baseURL: "https://node-api-to-mong.vercel.app",
});

/**
 * This is an example of animating shared layouts in Framer Motion 2.
 *
 * The open state of each panel is contained locally to that component. Wrapping
 * them all in the same AnimateSharedLayout component allows them all to animate
 * in response to state changes that affect each other's layout.
 *
 * Try removing AnimateSharedLayout to see how that affects the animation.
 */

export default function DisplayTasks() {
  const user = useContext(UserContext);

  //Since or if we have set todos in previous compomnent should be able
  // to use values here without fetching again. See how to get them
  //from application state ; may need to use redux or useReduce?
  //When I fetch again I don't like images too slow to paint.

  const [todos, setTodos] = useState([]);
  const [parentCounter, setParentCounter] = useState(0);

  function cNum(param) {
    return param === "" ? NaN : Number(param);
  }

  useEffect(() => {
    let JWT = AuthHeader();
    const fetchData = async () => {
      var config = {
        url: "/todos",
        method: "get",
        headers: {
          "x-auth-token": `${JWT}`,
          "content-type": "application/json",
        },
      };

      await Axios(config).then((res) => {
        const data = res.data;
        // console.log(data)
        const values = Object.values(data);

        console.log(values);
        //  console.log(entries)
        const rvalues = values.reverse();
        setTodos(rvalues);
      });
    };
    fetchData();
  }, []);

  return (
    <>
      <h1>Welcome, {user.name}</h1>
      <Setpriority setParentCounter={setParentCounter} />
      <div className="dscroller">
        <AnimateSharedLayout>
          <motion.ul layout initial={{ borderRadius: 15 }}>
            {/* console.log(todos) */}
            {todos.flatMap((todo) =>
              todo.priority > cNum(parentCounter) ? (
                <div
                  key={todo._id}
                  style={
                    todo.priority === 10
                      ? { color: "red" }
                      : todo.priority === 7
                      ? { color: " greenyellow" }
                      : todo.priority === 8
                      ? { color: "greenyellow", fontWeight: "900" }
                      : todo.priority === 9
                      ? { color: "yellow", fontWeight: "900" }
                      : { color: "white" }
                  }
                >
                  {/* {Math.round(Math.random() * (500 - 100)) + 100} */}
                  {/* {todo._id.match(/\d{3}/)[0]} */}
                  <Item key={todo._id} todo={todo} />
                </div>
              ) : (
                []
              )
            )}
          </motion.ul>
        </AnimateSharedLayout>
      </div>
    </>
  );
}

function Item(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <motion.li layout onClick={toggleOpen} initial={{ borderRadius: 10 }}>
      <motion.div className="avatar" layout>
        <img
          className="mimg"
          alt="pokemon"
          src={`https://assets.pokemon.com/assets/cms2/img/pokedex/detail/${
            Math.round(Math.random() * (500 - 100)) + 100
          }.png`}
        />
      </motion.div>
      <motion.div>{props.todo.priority}</motion.div>
      <motion.div>{props.todo.text}</motion.div>
      <AnimatePresence key={props.todo.id}>
        {isOpen && <Content />}
      </AnimatePresence>
    </motion.li>
  );
}

function Content() {
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="row" />
      <div className="row" />
      <div className="row" />
    </motion.div>
  );
}

// function myTime() {
//     var d = new Date();
//     var n = d.getTime();
//     return n
// }
//const items = [0, 1, 2];
// function getRandomArbitrary(min, max) {
//   return Math.random() * (max - min) + min;}
// let x = getRandomArbitrary(100, 400)
// Math.round(Math.random() * (500 - 100)) + 100
