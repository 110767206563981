import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext";
import TodoApp from "./TodoApp"
//import TodoList from "./todocomponents/TodoList"

export default function Home() {
  const { userData } = useContext(UserContext);

  return (
    <div className="page">
      {userData.user ? (
        <>
        {/* <h1>Welcome {userData.user.displayName}</h1> */}
        {/* <h1>You are in the todo app Enter a Todo</h1> */}
        <TodoApp />
       </>
      ) : (
        <>
          <h2>You are logged out</h2>
                 
          <Link className="RegBtn" to="/login">Log in</Link>
        </>
      )}
    </div>
  );
}
