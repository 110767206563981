import React, { useState, useEffect, useRef, useContext } from 'react';
import UserContext from "../../../context/UserContext";

function TodoForm(props) {
  const [input, setInput] = useState(props.edit ? props.edit.value : '');
  const [input2, setInput2] = useState(props.edit ? props.edit.value2 : '');
 
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  
  const user = useContext(UserContext)



  useEffect(() => {
    inputRef.current.focus();
  });


  function randomString() {
    var chars = "!@~#$%?]0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var string_length = 14;
    var randomstring = '';
    for (var i=0; i<string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum,rnum+1);
  //    console.log(randomstring);
    }
    return randomstring
   } 

  function randomId () { const myArray= [...Array(1)].map(e=>~~(Math.random()*100000000))
  //  console.log(myArray[0]);
   myArray[0] += randomString()
    // console.log(myArray[0]);
     let str = myArray[0]
     let shuffle = str.split('').sort(function(){return 0.5-Math.random()}).join('')
    console.log(shuffle);
     return shuffle
  } 


 const handleChange = e => {
    setInput(e.target.value);
     };

  //  const handleChange2 = e => {
  //   setInput2(e.target.value);
  //    };

  const handleSubmit = e => {
    e.preventDefault();

    console.log(user) //returning user.id = '5f78e0375736cd5c2894550f'
    const userArray = Object.values(user);
   console.log(userArray)
   let result = userArray.map(({ user }) => user)
   console.log(result)
   let result2 = result[0]
   console.log(result2.id)
   let id = result2.id


    props.onSubmit({
      _id: randomId(), 
      text: input,
      priority: 1,
      creator_id:`${id}`
    });
    setInput('');
  };


  const handleUpdate = e => {
    e.preventDefault();

    props.onSubmit({
      _id: props.edit._id ,     // need current id. or maybe _id of previous state3    //randomId(), 
      text: input,
      priority:input2
    });
    setInput('');
    setInput2(0);
  };

  return (
    <form onSubmit={handleSubmit} className='todo-form'>
      {props.edit ? (
        <>
          <input
            type="string"
            placeholder='Update your item'
            defaultValue={input}
            onChange={handleChange}
            name='text'
            ref={inputRef}
            className='todo-input edit'
          />
          <input
            type="number"
            placeholder='Set P'
            // value={input2}
            onChange={e =>{setInput2(e.target.value)}}
            name='priority'
            ref={inputRef2}
            className='todo-input-p edit'
            defaultValue={input2} 
          />
          <button onClick={handleUpdate} className='todo-button edit'>
            Update
          </button>
        </>
      ) : (
        <>
          <input
            placeholder='Add a todo'
            value={input}
            onChange={handleChange}
            name='text'
            className='todo-input'
            ref={inputRef}
          />
          <button onClick={handleSubmit} className='todo-button'>
            Add todo
          </button>
        </>
      )}
    </form>
  );
}

export default TodoForm;
