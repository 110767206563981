import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";

export default function AuthOptions() {
  const { userData, setUserData } = useContext(UserContext);

  const navigate = useNavigate();

  const discover = () => navigate("/discover");
  const register = () => navigate("/register");
  const login = () => navigate("/login");
  const logout = () => {
    setUserData({
      token: undefined,
      user: undefined,
    });
    localStorage.setItem("auth-token", "");
    navigate("/");
  };

  return (
    <nav className="auth-options">
      {userData.user ? (
        <button className="AuthBtn" onClick={logout}>
          Log out
        </button>
      ) : (
        <>
          <button className="AuthBtn" onClick={discover}>
            Discover
          </button>
          <button className="AuthBtn" onClick={register}>
            Register
          </button>
          <button className="AuthBtn" onClick={login}>
            Join
          </button>
        </>
      )}
    </nav>
  );
}
