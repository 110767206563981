import React from 'react'
import './Home.css'
import { motion } from "framer-motion";
import Wave from "./Wave.js";


export default function Home() {

    return (
        <>
<div className="container-h full-height-grow">
<section className="home-main-section">
<motion.div className="img-wrapper"
initial={{ scale:.5, x:0,y:+300 , opacity: 0}}
animate={{ scale:1.1, x:0, y:50 , opacity: 1}}
transition = {{type:'tween', delay:1, duration:1.5}} 
>
<div className="lady-image"></div>
</motion.div>
<div className="call-to-action">
<h1 className="title">Build The Code</h1>
<span className="subtitle">Plan out your day with our app</span>
<a href="/login" className="jbtn">Join Now</a>
</div>

<Wave />



<motion.div className="home-page-circle-1"
initial={{ scale:.5, x:-100,y:-100 , opacity: 0}}
animate={{ scale:.9, x:25, y:100 , opacity: 1}}
transition = {{type:'tween', delay:1, duration:1.5}} 
>
</motion.div>


<motion.div className="home-page-circle-2"
initial={{ scale:.5, x:-200,y:-200 , opacity: 0}}
animate={{ scale: 1, x:0 , y: 0 , opacity: 1}}
transition = {{type:'tween', delay:1.5, duration:2.0}} 
>
</motion.div>

{/* <div className="home-page-circle-1"></div> */}
{/* <div className="home-page-circle-2"></div> */}
{/* <div className="home-page-circle-3"></div> */}
</section>
</div>
</>
    )
}





